import React from "react"

import "../../scss/components/main/LogoLine.scss"
import nodejs from "../../images/bandeau1/Node.js_logo.svg"
import golang from "../../images/bandeau1/Go_Logo_Blue.svg"
import php from "../../images/bandeau1/PHP-logo.svg"
import ruby from "../../images/bandeau1/Devicon-ruby-plain.svg"
import python from "../../images/bandeau1/Python-logo-notext.svg"
import rust from "../../images/bandeau1/rust-language-icon.svg"
import java from "../../images/bandeau1/Java_Logo.svg"
import dotnet from "../../images/bandeau1/netcore.png"
import elixir from "../../images/bandeau1/elixir.png"

function LogoLine() {
  return(
    <div className="bandeau logoLine">
      <div className="logoLine__smallTextUpper">
        <span>Adapté à votre language ou à votre framework</span>
      </div>
      <br />
      <div className="supports">
        <div className="supports__item">
          <div className="icon">
            <div className="icon">
              <img src={nodejs} alt="square_container" className="img-nodejs" />
            </div>
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={golang} alt="square_container" className="img-golang" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={php} alt="square_container" className="img-php" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={ruby} alt="square_container" className="img-ruby" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={python} alt="square_container" className="img-python" />
          </div>
        </div>
      </div>
      <br />
      <div className="supports">
        <div className="supports__item">
          <div className="icon">
            <img src={rust} alt="square_container" className="img-rust" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={java} alt="square_container" className="img-java" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={dotnet} alt="square_container" className="img-dotnet" />
          </div>
        </div>
        <div className="supports__item">
          <div className="icon">
            <img src={elixir} alt="square_container" className="img-elixir" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoLine