/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeTwo.scss"

import square from "../../images/bandeau2/square_conteneurisation.png"

function StripeTwo() {


  return (
    <div className="bandeau zone2 marged">
      <div className="zone2__header">
        <div className="zone2__header__bg"></div>
        <img className="zone2__header__bgImg" src={square} alt="square_container" />
        <div className="zone2__header__text">
          <span className="bigText">Faites moins, codez plus !</span>
          <span className="smallText">Déployez et gérez vos applications, via une infrastructure puissante, l'esprit serein. </span>
        </div>
      </div>
    </div>

  )
}

export default StripeTwo