import React from "react"
import Layout from "../components/decorate/Layout"

import StripeOne from "../components/main/StripeOne"
import StripeTwo from "../components/main/StripeTwo"
import LogoLine from "../components/main/LogoLine"
import StripeThree from "../components/main/StripeThree"
import StripeFive from "../components/main/StripeFive"
import StripeEight from "../components/main/StripeEight"
import StripeSix from "../components/main/StripeSix"

function Index () {
    return(
      <Layout>

        <section className ="homepage">
          <StripeOne />
          <StripeTwo />
          <StripeThree />
          <LogoLine />
          <StripeFive />
          <StripeSix />
          <StripeEight />
        </section>
      </Layout>
    )
}
export default Index
