/*Import de librairies*/
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faCode, faRunning, faCubes } from "@fortawesome/free-solid-svg-icons"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeThree.scss"


function StripeThree() {
  const dataZone3 = [

    {
      anchor: <FontAwesomeIcon icon={faCode} /> ,
      text:"Focus sur le code",
      description :"Construisez vos applications, plus simplement au lieu de fusionner avec les environnements de développement et les déploiements de production",
      key:"helping"
    },
    {
      anchor: <FontAwesomeIcon icon={faRunning} /> ,
      text:"Exécution accélérée",
      description :"Nous avons réglé les runtimes et les configurations pour que votre code s’exécute plus rapidement que sur votre système d’exploitation hôte.",
      key: "crown"
    },
    {
      anchor: <FontAwesomeIcon icon={faBoxOpen}/>,
      text:"Cohérence",
      description :"GetCaaS créée une plateforme de développement unifiée à travers les diverses configurations et systèmes d’exploitation de votre équipe.",
      key: "shield"
    },
    {
      anchor: <FontAwesomeIcon icon={faCubes} /> ,
      text:"Isolation",
      description :"Les environnements d’exécution, les dépendances et les bases de données de votre application sont isolées à l’intérieur de conteneurs qui peuvent être lancés, arrêtés ou détruits à tout moment.",
      key: "faster"

    },
  ]
  return (
    <div className="bandeau zone3 marged">
      <div className="zone3__header__text ">
        <span className="bigText"><span>N'installez rien</span>. GetCaaS vous aide à préparer votre environnement de développement au moyen de la <span>conteneurisation</span> </span>
      </div>
      <div className="supports">
        {
          dataZone3.map( el => {
            return (
              <div className="supports__item" key={el.key}>
                <div className="icon">
                  {el.anchor}
                </div>
                <div>
                  <p  className="text">{el.text}</p>
                  <p className="description">{el.description}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default StripeThree