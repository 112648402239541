/*Import de librairies*/
import React from "react"
import "../../scss/components/main/StripeOne.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import ReactTypingEffect from "react-typing-effect"

/*Import de fichiers en local*/
import bateau_plateforme from "../../images/bandeau1/Image-Bateau-Plateforme-mini.png"

function StripeOne() {

  return (
    <div className="bandeau zone1 marged">
      <div className="principal">
        <div className="textIndex">
          <div>Déployez plus <span>rapidement</span> vos <br />
            <span>
              <ReactTypingEffect speed={60} eraseSpeed={40}
                 eraseDelay={2000} typingDelay={10}
                 text={["APIs", "sites web", "conteneurs", "bases de données", "applications"]} />
            </span>
          </div>
        </div>
        <p className="subTextIndex"><span>Sortez votre tête du cloud !</span> Avec GetCaaS, lancez n'importe quelle application dans n'importe quel cloud.</p>
        <div className="buttons">
          <a className="btn btn-grad" href="https://app.ongetcaas.io/auth/register">Créer un compte <span className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></span></a>
          <a  href="https://calendly.com/getcaas-io/presentation-de-la-plateforme-getcaas" target="_blank" rel="noreferrer" className="btn  btn-empty" >
            Demander une démo
          </a>
        </div>
      </div>
      <div className="image">
        <img src={bateau_plateforme} title="Bateau & quai" alt="image_bateau_plateforme_indisponible" />
      </div>
    </div>
  )
}

export default StripeOne