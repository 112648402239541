/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeFive.scss"
import square from "../../images/bandeau5/carre_projet_souhaite.png"
import services from "../../images/bouton_Git.svg"


function StripeFive() {


  return(
    <div className="zone5">
      <div className="zone5__content">
        <div className="zone5__content__bg"></div>
        <img className="zone5__content__bgImg" src={square} title="Votre projet comme vous le souhaitez" alt="image_indisponible" />
        <div className="zone5__content__main">
          <div className="zone5__content__main__head">
            <h2 style={{color:"white"}} className="bigText">Votre projet comme vous le souhaitez, avec un déploiement simplifié !</h2>
            <p className="smallText">Définissez vos besoins depuis notre interface ou passez par nos bots de déploiements.</p>
          </div>
          <img src={services} title="Votre projet comme vous le souhaitez" alt="image_indisponible" />
        </div>

      </div>
    </div>
  )
}

export default StripeFive