/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeEight.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

function StripeEight() {
  return (
    <div className="bandeau zone8 marged">
      <p className="title-area-homepage">Une <span>flexibilité</span> qui évolue en fonction de vos besoins. </p>
      <p className="subText">Votre infrastructure ne devrait jamais ralentir votre équipe ou restreindre vos activités.</p>
      <div>
        <a className="btn btn-grad mr-5" href="https://app.ongetcaas.io/auth/register">Créer un compte <span className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></span></a>
        <a  href="https://calendly.com/getcaas-io/presentation-de-la-plateforme-getcaas" target="_blank" rel="noreferrer" className="btn  btn-empty" >
          Demander une démo
        </a>
      </div>


    </div>
  )
}

export default StripeEight