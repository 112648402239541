
/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeSix.scss"
import cl1 from "../../images/clients/BFM.webp"
import cl2 from "../../images/clients/digital113.png"
import cl3 from "../../images/clients/NWX_logo_noir.png"
import cl4 from "../../images/clients/adocc-logo.webp"
import cl5 from "../../images/clients/logo_innopolis.svg"

function StripeSix() {

  const dataZone5 = [
    {
      img : cl1
    },
    {
      img : cl2
    },
    {
      img : cl3
    },
    {
      img : cl4
    },
    {
      img : cl5
    },
  ]

  return (
    <div className="bandeau zone6 marged">
      <p className="title-area-homepage">Nos partenaires</p>
      <div className="client-grid marged">

        {
          dataZone5.map(el => {

            return (
              <div className="img-client" >
                <img src={el.img} alt="img_client_indisponible"/>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default StripeSix